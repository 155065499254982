.main {
  margin-top: 8vh;
  padding: 0 10px;
  height: 81vh;
}
.paymentslip {
  background: #f9f9f9;
  border-radius: 16px;
  position: relative;
  top: 15px;
  padding: 15px;
  margin-bottom: 25px;
}
.paymentsliptop {
  display: flex;
}
.invoice {
  margin-right: 15px;
}
.locationhead {
  font-size: 15px !important;
  color: #002236;
  font-weight: 600 !important;
  /* max-width: 90vw; */
}
.locationtitle {
  font-size: 13px !important;
  color: #002236;
  font-weight: 400 !important;
  max-width: 60vw;
}
.paymentdetails {
  color: #002236;
  font-size: 14px !important;
  font-weight: 700 !important;
  margin: 10px 0 !important;
}
.bill {
  display: flex;
  color: #002236 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  margin-bottom: 0 !important;
}
.billcost {
  font-weight: 700 !important;
}
.hidebill {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #e3552e !important;
}

.p-childdesign {
  background-color: #f9f9f9;
  border-radius: 16px;
  margin-bottom: 10px;
  display: flex;
  padding: 10px;
  align-items: center;
  position: relative;
}
.applybutton {
  background-color: #e3552e !important;
  position: absolute !important;
  right: 10px !important;
  border-radius: 16px !important;
  bottom: 13px !important;
  width: 70px;
  height: 32px;
}
.removeButton {
  color: red !important;
  margin-left: auto;
}
.borderbottom {
  border-bottom: 1px solid #e3ebea;
  padding: 3px 0;
}
.proceedpay {
  border-radius: 16px;
  display: flex;
  padding: 0 20px !important;
  margin-bottom: 37px;
}
.tcost {
  width: 50%;
  padding: 7px 0;
  padding-left: 20px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  color: white;
  /* background-color: #ee775a; */
  background: transparent;
  font-size: 18px !important;
  font-weight: 700 !important;
}
.tcostt {
  font-size: 13px !important;
  font-weight: 700 !important;
}
.ppayment {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: white;
  /* background-color: #e3552e; */
  background: transparent;
  width: 50%;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
}
.payment_terms {
  color: #002236 !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  margin-top: 5px !important;
}

.payment_terms > li {
  opacity: 0.7 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  margin-top: 5px !important;
}
.payment_terms_list {
  opacity: 0.7 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-top: 5px !important;
}
