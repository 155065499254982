.toplay {
    padding: 20px 20px 0 20px !important;
}

*:focus-visible, *:focus{
    outline: none;
}

label{
    user-select: none;
}

.i-package {
    color: #002236 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}

.i-swipe-package {
    margin-top: 16px;
}

.i-swiper-itemss {
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
}

.i-package-imgg-lay {
    height: 70px;
    width: 70px;
    background: rgba(227, 85, 46, 0.1);
    border-radius: 50%;
    position: relative !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.activeee {
    height: 70px;
    width: 70px;
    background: #E3552E !important;
    border-radius: 50%;
    position: relative !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.i-package-imgg {
    position: absolute !important;
    height: 50% !important;
    width: 50% !important;
}

.i-package-item-name {
    color: #002236 !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    margin-top: 5px !important;
}

.textareaa {
    border-radius: 16px !important;
}

.css-7209ej-MuiInputBase-input-MuiFilledInput-input {
    color: #002236 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.css-85zwa9-MuiInputBase-root-MuiFilledInput-root {
    border-radius: 12px !important;
    background: #F4F6F8 !important;
}

.i-fragile {
    color: #002236 !important;
    font-size: 15px !important;
    font-weight: 400 !important;
}

.css-julti5-MuiSwitch-root {
    width: 44px !important;
    height: 28px !important;
    border-radius: 100px !important;
    padding: 0 !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase {
    padding: 4px !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    color: white !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    -webkit-transform: translateX(17px);
    -moz-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    background-color: #E04E2C !important;
    opacity: 1 !important;
}

.i-df {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.i-df-image {
    margin-right: 17px;
}

.css-wy7lpg-MuiFormControlLabel-root {
    margin-right: unset !important;
}

.i-mb {
    border-bottom: 1px solid #E3EBEA;
    margin: 20px 0;
}

.i-imagee-inputt {
    height: 106px !important;
    width: 106px !important;
    background: #FCEEEA !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    position: absolute;
}

.i-imagee-inputt::before {
    content: url('../images/PlusCircle.svg');
    height: 106px !important;
    width: 106x !important;
    background: #FCEEEA !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.i-imgg-resize {
    height: 100px !important;
    width: 100px !important;
    position: relative !important;
    border-radius: 16px;
    background-color: white !important;
}

.i-imgg-resizee {
    position: absolute;
    height: 107%;
    width: 107%;
    object-fit: cover;
    border-radius: 16px;
}

.i-del-iconn {
    position: absolute !important;
    left: 6px !important;
    top: 6px !important;
}

.i-main-boxx {
    position: relative;
    height: 106px !important;
    width: 106px !important;
    border-radius: 16px;
    overflow: hidden !important;
    margin-bottom: 5px !important;

}

.i-capture-box {
    display: flex;
    flex-wrap: wrap !important;
    justify-content: space-between;
    row-gap: 6px;
    margin-top: 16px;
}


.css-85zwa9-MuiInputBase-root-MuiFilledInput-root:after {
    border-bottom: unset !important;
}

.css-o943dk-MuiFormLabel-root-MuiInputLabel-root {
    color: #9D9E9E !important;
    font-size: 15px !important;
    font-weight: 400 !important;
}

.i-add-voice {
    color: #E3552E;
    font-size: 16px !important;
    font-weight: 700 !important;
}

.i-voice-box {
    width: 65px;
    height: 65px;
    background: rgba(227, 85, 46, 0.1);
    border-radius: 16px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.i-voice-box-main {
    display: flex;
    align-items: center;
    margin: 20px 0 !important;
}

.i-save-inst {
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #F6F6F9 !important;
    text-transform: none !important;
    background: #E3552E !important;
    border-radius: 16px !important;
    width: calc(100% - 28px) !important;
    padding: 15px 0 !important;
    position: fixed !important;
}

.textarea-instruction {
    width: 100%;
    border-radius: 12px;
    background: #F4F6F8;
    outline: none;
    padding: 16px;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    resize: none;
}

.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 28px;
    overflow: hidden;
    border-radius: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #F4F6F8;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    user-select: none;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #E04E2C;
}

input:focus+.slider {
    box-shadow: 0 0 0px #E04E2C;
}

input:checked+.slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}