.main{
    padding:unset !important;
    margin-top: 8vh;
}
.content{
    display:flex;
    justify-content:space-between;
    align-items: center;
    padding:10px 1vw;
    box-shadow: 0 0 2px 2px #999;
    
}
.tab{
    display:flex;
    box-shadow: inset 0 -1px 1px 0px #E8ECEE;
    height: 52px !important;
}
.tab button {
    background-color: inherit;
    border: none;
    border-bottom: 2px solid transparent;
    outline: none;
    cursor: pointer;
    font-weight: 500;
    transition: 0.3s;
    font-size: 18px;
    color: rgb(0 34 54 / 50%);
    width: 50% !important;
    padding: 10px 0 !important;
    height: 52px !important;
  }
  .tab button.active {
    /* border-bottom: 3px solid #E3552E; */
    border-bottom: 2px solid #e3552e;
    background-color: unset !important;
    transition-duration: .5s !important;
    color: #002236;
  }
  .tabcontent {
    display: none;
    border-top: none;
  }
  .tabcontent.show {
    display: block;
  }  
  .tab-item{
    display:flex;
    border-radius:20px;
    background-color: #F9F9F9;
    align-items: center;
    margin-top: 16px;
    position: relative;
    padding: 15px 6px !important;
    /* margin-bottom: 5px !important; */
  } 
  .tab-item img{
    margin-right: 10px !important;
  }
  .top-item{
    font-weight: 400 !important;
    font-size: 15px !important;
    color: #002236;
  }
  .buttom-item{
    font-weight: 700 !important;
    font-size: 15px !important;
    color: #002236;
  }
  .button-pick{
    color:white !important;
    background-color: #57C28A !important;
    border-radius: 6px !important;
    font-size: 11px !important;
    font-weight: 700 !important;
    padding: 5px !important;
    position: absolute !important;
    right: 10px !important;
  }
  .button_pick{
    color:white !important;
    background-color: #002236 !important;
    border-radius: 6px !important;
    font-size: 11px !important;
    font-weight: 700 !important;
    padding: 5px !important;
    position: absolute !important;
    right: 10px !important;
  }