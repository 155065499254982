.payment-detail{
    background-color: #E04E2C;
    display:flex;
    margin-top: 60px;
    padding:15px 5px;
    align-items: center;

}
.payment-detail img{
    /* margin: 0 15px; */
    margin-right: 10px !important;
}
.balance-msg{
 color:white;
 font-weight: 400;
 font-size: 14px;
}
.amount{
    color:white;
    font-weight: 700;
    font-size: 24px;
}
.button-box{
    display:flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: absolute;
    right:10px;
    height:32px;
    width:32px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0px 8px 15px rgba(227, 85, 46, 0.3);
}
.button-box img{
 position: absolute !important;
 height: 70% !important;
 width: 70% !important;
 margin: unset !important;
}
.phtab-item-top{
padding-top: 15%  !important;
}
.phtab-item{
    display:flex;
    border-radius:20px;
    background-color: #F9F9F9;
    align-items: center;
    margin-top: 16px !important;
    position: relative;
    padding: 15px 8px !important;
}
.phtab-item img{
    margin-right: 10px !important;
  }
  .phtop-item{
    font-weight: 400 !important;
    font-size: 12px !important;
  }
  .phbuttom-item{
    font-weight: 600 !important;
    font-size: 15px !important;
    color:#002236;
}
.phright-tab{
    color:#002236;
    position:absolute !important;
    right:10px !important;
}
