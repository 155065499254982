.main {
  margin-top: 8vh;
}

.backgroundmap {
  height: 45vh;
}

.bottomdesign {
  padding: 15px 20px 0 20px;
}

.paddress {
  color: #002236 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  margin-bottom: 10px !important;
  display: flex;
}

.dcontain-business {
  background-color: #f9f9f9;
  border-radius: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start !important;
  padding: 10px;
  justify-content: center;
}

.locationhead {
  font-size: 15px !important;
  color: #002236;
  font-weight: 600 !important;
  /* min-width: 70vw; */
}

.locationtitle {
  font-size: 13px !important;
  color: #002236;
  font-weight: 400 !important;
  max-width: 60vw;
}

.locicon {
  margin-right: 10px;
}

.childdesignnnn {
  background-color: #f9f9f9;
  border-radius: 16px;
  margin-bottom: 10px;
  display: flex;
  padding: 10px;
  align-items: center;
  position: relative;
}

.childdesignnnnn {
  border: 1px solid#C7D6D5;
  border-radius: 16px;
  margin-bottom: 10px;
  display: flex;
  padding: 10px;
  align-items: center;
  position: relative;
}

.lociconn {
  position: absolute;
  right: 2px !important;
}

.lociconnnnn {
  height: 12px !important;
}

.borderbottomm {
  border-bottom: 1px solid #e3ebea;
  margin-bottom: 15px;
  margin-top: 15px;
}

.proceedpayy {
  border-radius: 16px;
  display: flex;
}

.applybuttonnnnn {
  background-color: #e3552e !important;
  position: absolute !important;
  right: 10px !important;
  border-radius: 16px !important;
}

.mborderspace {
  height: 25px !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: unset !important;
  border-radius: 16px !important;
}

.business-order-container {
  background-color: white;
  /* box-shadow: 0px -16px 22px 7px rgba(0, 0, 0, 0.1); */
  border-radius: 30px 30px 0px 0px;
  bottom: 0 !important;
  position: relative;
  width: 100%;
}
