html,
body {
  height: 100%;
}

* {
  font-family: "Cabin", sans-serif !important;
}

#root {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
}

.button {
  color: #f5f5f5 !important;
  width: 96% !important;
  padding: 16px 0px !important;
  border-radius: 16px !important;
  position: absolute !important;
  left: 2%;
  bottom: 20px !important;
}

.btn_red {
  background-color: #e3552e !important;
}

.btn_blue {
  background-color: #002236;
}

.btn_inactive {
  color: #c7d6d5 !important;
  border: 1px solid #c7d6d5 !important;
}

.loader_img {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0, auto !important;
  margin-top: 240px !important;
  height: 30vh !important;
  width: 94vw !important;
}

.header_notification {
  position: fixed !important;
  top: 0 !important;
  width: 100vw !important;
  box-shadow: -2px 2px 4px 0px #cccbcb40;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0 20px !important;
  height: 60px !important;
  margin-bottom: 0px !important;
  background-color: #002236;
  z-index: 10 !important;
}

/* ===================================================================================
                                    Common Styles
======================================================================================= */
.text_center {
  text-align: center !important;
}

.element_center {
  margin: 60px auto 15px !important;
  display: inherit !important;
  max-width: 200px;
}

.contain {
  padding: 0 2.5vw;
}

.phorizontal {
  padding: 0 20px 20px !important;
}

.d-change-colour {
  color: black !important;
}

.text_blue_fs14_700 {
  font-size: 14px !important;
  color: #002236 !important;
  font-weight: 700 !important;
}

.text_blue_fs18_700 {
  color: #002236 !important;
  font-weight: 700 !important;
  font-size: 18px !important;
}

.text_gray_fs14_700 {
  color: #9d9e9e;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.mg_top10 {
  margin-top: 10px !important;
}

.css-85zwa9-MuiInputBase-root-MuiFilledInput-root:after {
  border-bottom: unset !important;
}

.css-o943dk-MuiFormLabel-root-MuiInputLabel-root {
  color: #9d9e9e !important;
  font-size: 15px !important;
  font-weight: 400 !important;
}

.loading_box_height {
  height: 70vh !important;
}

.recenter {
  position: absolute !important;
  bottom: 100px !important;
  right: 20px !important;
  background-color: #ffffff !important;
  color: #002236 !important;
  cursor: pointer;
  border-radius: 50%;
  border: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 2px 1px #0000006e;
}

.background_image {
  background-image: url("./assets//images/login_backgroundimg.svg") !important;
  height: 50vh !important;
  background-repeat: no-repeat !important;
  position: absolute !important;
  width: calc(100% - 20px) !important;
  background-position: bottom !important;
  bottom: 15vh !important;
  background-size: 100% !important;
  z-index: -1;
}

.box_container {
  display: flex;
  justify-content: center !important;
  position: absolute !important;
  bottom: 120px;
  width: calc(100% - 20px);
}

.lpbutton-boxactive {
  color: white !important;
  background-color: #001f31 !important;
  border: 1px solid #001f31 !important;
  padding: 16px 0px !important;
  border-radius: 16px !important;
  max-width: 500px;
  width: 100%;
  margin-top: 30px !important;
  align-self: center;
  text-transform: capitalize !important;
}

.button_container {
  display: flex;
  justify-content: center !important;
  position: absolute !important;
  bottom: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: calc(100% - 20px);
  left: 2%;
}

.lpbutton-boxinactive {
  color: #c7d6d5 !important;
  border: 1px solid #c7d6d5 !important;
  max-width: 500px;
  width: 100%;
  padding: 16px 0px !important;
  border-radius: 16px !important;
  margin-top: 30px !important;
  align-self: center;
  text-transform: capitalize !important;
}

.react-tel-input .form-control {
  box-shadow: 0px 4px 50px 0px #0000000d !important;
  padding: 28px 0 !important;
  padding-left: 65px !important;
  border: 1px solid #f4f6f8;
  border-radius: 16px !important;
  height: 35px;
  width: 100% !important;
}

.react-tel-input .flag-dropdown {
  background: #ffffff;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  border-right: 0;
  border-radius: 17px 0 0 17px !important;
  box-shadow: inset 0px 0px 0px 1px #f4f6f8 !important;
}

.react-tel-input .selected-flag {
  width: 51px !important;
  padding: 0 0 0 14px !important;
  border-radius: 17px 0 0 17px !important;
}

.react-tel-input {
  width: 100% !important;
  max-width: 500px !important;
  margin: 0 !important;
  align-self: center;
}

.react-tel-input .country-list {
  width: 100vw !important;
  max-width: 320px !important;
  border-radius: 0 0 10px 10px;
}

.lo-log-box {
  width: auto !important;
  display: block !important;
  padding: 5px;
  margin-top: 20px;
  margin: 0 auto !important;
}

.lo-otp-box {
  display: flex !important;
  margin-top: 30px !important;
  justify-content: center;
}

.lo-lpbutton-box {
  color: white !important;
  background-color: #001f31 !important;
  border: 1px solid #001f31 !important;
  padding: 16px 0px !important;
  border-radius: 16px !important;
  max-width: 500px;
  width: 100%;
  margin-top: 30px !important;
  align-self: center;
  text-transform: capitalize !important;
}

.lo-box-container {
  display: flex;
  align-items: center;
  justify-content: center !important;
  position: absolute !important;
  bottom: 120px;
  width: calc(100% - 20px);
}

.lo-text-headd {
  font-size: 14px !important;
  color: #e3552e !important;
  font-weight: 700 !important;
}

.lo-tdu {
  text-decoration: underline;
  cursor: pointer;
}

.lo-tdu:hover {
  cursor: pointer;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.flex-fill {
  flex: 1 !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-auto {
  margin-left: auto !important;
}

.me-auto {
  margin-right: auto !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.text-center {
  text-align: center !important;
}

.ff_Cabin {
  font-family: "Cabin", sans-serif !important;
}

/* app.css */
/* ------------notification header start--------------- */

.notname {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #fff !important;
}

body::-webkit-scrollbar {
  display: none !important;
}

.css-85zwa9-MuiInputBase-root-MuiFilledInput-root:after {
  border-bottom: unset !important;
}

.css-o943dk-MuiFormLabel-root-MuiInputLabel-root {
  color: #9d9e9e !important;
  font-size: 15px !important;
  font-weight: 400 !important;
}

.error {
  font-family: Kia Signature Regular;
  font-size: 12px;
  margin-bottom: 15px;
  color: #ea0029;
  margin-left: 10px;
  height: 15px;
}

.bg-emerald {
  background-image: url("./assets//images/login_backgroundimg.svg") !important;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

[data-rsbs-backdrop] {
  z-index: 99;
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 100;
}

.header_offset {
  padding-top: 60px !important;
}

.hor_space {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.sec_top_spacing {
  margin-top: 16px;
}

.pac-logo:after {
  display: none !important;
}

.logOutBtn,
.deleteAccBtn {
  color: #fff !important;
  padding: 16px 0px !important;
  border-radius: 16px !important;
  text-transform: capitalize !important;
}

.logOutBtn {
  background-color: #e3552e !important;
}

.deleteAccBtn {
  background-color: #002236 !important;
}

.changeName {
  color: #e3552e !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}

.pkg_name_loader {
  height: 20px;
  width: 55px;
  background: rgb(244 246 248);
  border-radius: 5px;
}

.layered:before {
  content: "";
  z-index: 99;
  height: 100%;
  width: 100%;
  position: absolute;
}

.linkAvailabilityStatus {
  display: flex;
  position: absolute;
  right: 10px;
  top: 2px;
}

.linkAvailabilityStatus img {
  height: 10px;
}

.availableLinks span:first-of-type:before {
  display: none;
}

.availableLinks span {
  font-size: 12px;
  padding: 0 0 0 6px;
  position: relative;
  font-weight: 700;
}

.availableLinks span:before {
  content: ",";
  position: absolute;
  left: 0;
}

.availableLinks label {
  color: #9d9e9e;
  font-size: 12px;
  margin-bottom: 0;
}

.payDetailsBtn {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
}

.payDetailsBtn:before,
.payDetailsBtn:after {
  content: "";
  position: absolute;
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  z-index: -1;
}

.payDetailsBtn:before {
  background: #ee775a;
  left: 0;
}

.payDetailsBtn:after {
  background: #e3552e;
  right: 0;
}

.payDetailsBtn.content-loader {
  background: linear-gradient(to right,
      rgb(227 85 46 / 30%),
      rgb(227 85 46 / 68%));
  animation-duration: 2s;
}

.payDetailsBtn.content-loader:after,
.payDetailsBtn.content-loader:before {
  display: none;
}

.content-loader {
  background: linear-gradient(to right,
      rgb(227 85 46 / 13%),
      rgba(227, 85, 46, 0.1));
  animation-duration: 5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: contentLoader;
  animation-timing-function: linear;
}

@keyframes contentLoader {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

img[alt="Google"] {
  display: none;
}

.gmnoprint {
  display: none;
}

.fillMap {
  position: absolute;
  height: 100%;
  width: 100%;
}

.openedBS {
  background-color: white;
  box-shadow: 0px -16px 22px 7px rgba(0, 0, 0, 0.1);
  border-radius: 30px 30px 0px 0px;
  padding: 20px 20px 40px;
  z-index: 1;
  max-height: 60%;
  overflow: auto;
}

.riderName strong {
  color: #000;
  display: block;
  font-size: 16px;
}

.riderName b {
  color: rgb(0 34 54 / 70%);
  font-size: 12px;
}

.rider a,
.rider button {
  background: #e3552e;
  border: 0;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.tipp,
.mark {
  border-radius: 80px;
}

.tipp input[type="radio"]:checked+span {
  background: #e3552e;
  box-shadow: 0px 10px 20px 0px #e3552e66;
  color: #fff;
}

.accepted,
.picked,
.dropped {
  position: relative;
}

.accepted:before,
.picked:before,
.dropped:before {
  position: absolute;
  color: #002236;
  font-size: 12px;
  top: 12px;
  font-weight: 400;
  min-width: 70px;
}

.accepted:before {
  content: "Accepted";
  left: 0;
  text-align: left;
}

.picked:before {
  content: "Picked Up";
  text-align: center;
  left: -16em;
}

.dropped:before {
  content: "Destination";
  right: 0;
  text-align: right;
}

.deliveryDetailsHeader {
  background: #57c28a;
  padding-top: 20px;
  padding-left: 20px;
}

.deliveryDetailsHeader h3 {
  color: #ffffff;
  font-size: 36px;
}

.deliveryDetailsHeader {
  color: #fff;
  border-radius: 0 0 30px 30px;
  overflow: hidden;
}

.deliveryDetailsHeader h4 {
  font-size: 16px;
  margin-top: 5px;
}

.deliveryDetailsHeader p {
  margin-top: 16px;
  font-size: 14px;
}

.emailSend {
  background: #e3552e;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  margin-top: 20px;
}

.imgGal {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  /* grid-template-rows: repeat(2,1fr); */
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.imgGal img {
  max-width: 100%;
}

.blobOne,
.blobTwo {}

.blobOne {
  width: 420px;
  height: 320px;
  position: absolute;
  background: #05283d;
  border-radius: 50% 80% 30%;
}

.blobTwo {
  width: 400px;
  height: 258px;
  position: absolute;
  background: #4563ac36;
  border-radius: 170% 86% 50% 77%;
  bottom: -70px;
}

.input_wrap {
  position: relative;
}

.input_wrap img {
  height: 20px;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.customized-date {
  text-indent: -9999em;
  border: 0;
  background-color: transparent;
  height: 60px;
  width: calc(50% - 28px);
  opacity: 1;
  position: absolute;
  z-index: 9;
}

.date-time .customized-date {
  pointer-events: none;
}

.rgty {
  font-size: 18px;
  color: #002236;
  border: 0;
  width: 100%;
  font-weight: 700;
  padding: 6px 4px 15px !important;
  background: transparent;
}

.rgty+label {
  font-size: 12px;
  transform: none;
  font-weight: 700;
}

.reactWhatsapp {
  display: block !important;
  border: 0;
  width: 100%;
  background: none;
  padding: 0 !important;
}

.business-order-container .header_notification {
  display: none !important;
}

.business-order-container .header_offset {
  padding-top: 0 !important;
}

.business-order-container .borderbottom {
  display: none;
}

.realTimeStage {
  width: 61px;
  height: 30px;
  color: #e3552e;
  padding: 4px 8px 4px 8px;
  border-radius: 100px;
  gap: 10px;
  background: #fceeea;
}