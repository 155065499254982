.add_image {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0, auto !important;
  margin: 0 auto !important;
  margin-top: 100px !important;
  height: 16vh !important;
}

.add_head {
  text-align: center !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  color: #002236 !important;
}

.add_form {
  display: flex;
}

.add_image_user {
  padding: 10px 5px !important;
  display: inherit !important;
  text-align: center !important;
  outline: none;
  margin-top: 30px;
  max-width: 500px;
  align-self: center;
  width: 100%;
  border: 1px solid #C7D6D5;
  height: 50px;
  border-radius: 16px;
}

/* image add design  */
.i_main_boxx {
  position: relative;
  height: 106px !important;
  width: 106px !important;
  border-radius: 16px;
  overflow: hidden !important;
  margin-bottom: 5px !important;
}

.i_imagee_inputt {
  height: 100px !important;
  width: 100px !important;
  background: #fceeea !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  position: absolute;
  margin-bottom: 30px !important;
}

.i_imagee_inputt::before {
  /* content: url('../../assets/img/inputplus.svg'); */
  height: 100px !important;
  width: 100px !important;
  background: #fceeea !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute !important;
  border-radius: 50px !important;
  object-fit: contain !important;
}

.i_imgg_resize {
  height: 100px !important;
  width: 100px !important;
  position: relative !important;
  border: 1px solid red;
  border-radius: 50px;
  background-color: white !important;
}

.i_imgg_resizee {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 50px;
}

.i_del_iconn {
  position: absolute !important;
  left: 33px;
  bottom: 0px;
}

.i_save_inst {
  color: white !important;
  background-color: #e3552e !important;
  border: 1px solid #e3552e !important;
  padding: 16px 0px !important;
  border-radius: 16px !important;
  max-width: 500px;
  width: 100%;
  margin-top: 10px !important;
  align-self: center;
  text-transform: capitalize !important;
}

.element_centerr {
  margin: 0 auto !important;
  display: inherit !important;
}

.text_center {
  font-size: 16px !important;
  /* color: #002236 !important; */
  display: flex !important;
  justify-content: center !important;
  margin: 10px 0 !important;
}

.bg_fix {
  background-color: rgb(230, 224, 224) !important;
}

.add_user_container {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  height: 100vh !important;
}

.profile-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.profile-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

label {
  margin-bottom: 10px;
  font-weight: bold;
}

input[type="text"] {
  padding: 10px;
  font-size: 18px;
  /* border: 2px solid #ccc; */
  border-radius: 5px;
  outline: none;
}

input[type="number"] {
  padding: 10px;
  font-size: 18px;
  /* border: 2px solid #ccc; */
  border-radius: 5px;
  outline: none;
}

.image-container {
  text-align: center;
  margin: 20px 0;
}

.image-container img{
  box-shadow: 0px 0px 15px 13px #00000033 !important;
  object-fit: cover;
}

.image {
  border-radius: 120px;
}

.profile-button{
display: flex;
flex-direction: column;

}

.log-out{
  width: 24px;
  height: 24;
  color: #002236;
}