.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 1;
}

.marker-new {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 1;
}

.map-container {
  position: relative;
  height: 100%;
}