.main {
  margin-top: 8vh;
}

.backgroundmap {
  height: 45vh;
}

/* .bottomdesign {
  background-color: white;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  margin-top: -20px;
  padding: 30px 15px 0 15px;
} */
.ppaddress {
  color: #002236 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  margin-bottom: 10px !important;
  display: flex;
}

.ppaddress-bottom {
  color: #002236 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  /* margin-bottom: 10px !important; */
  display: flex;
  padding: 20px;
}

.dcontain {
  background-color: #f9f9f9;
  border-radius: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start !important;
  padding: 10px;
}

.locationhead {
  font-size: 15px !important;
  color: #002236;
  font-weight: 600 !important;
  max-width: 90vw;
}

.locationtitle {
  font-size: 13px !important;
  color: #002236;
  font-weight: 400 !important;
  max-width: 60vw;
}

.locicon {
  margin-right: 10px;
}

.childdesign {
  background-color: #f9f9f9;
  border-radius: 16px;
  margin-bottom: 10px;
  display: flex;
  padding: 10px;
  align-items: center;
  border: none;
}

.p-a-lociconn {
  position: absolute;
  right: 25px;
}

.savemodal {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #e3552e;
  text-decoration: underline;
  margin-bottom: 10px !important;
}

.savemodal:hover {
  cursor: pointer;
}

.continueebtnn {
  background-color: #e3552e !important;
  width: 100%;
  border-radius: 16px !important;
  padding: 15px 0 !important;
  text-transform: capitalize !important;
  margin: 0 auto !important;
  margin: 14px 0px 42px 0 !important;
  color: #fff !important;
}

.modalinputt {
  width: 100%;
  margin: 15px 0 !important;
}

.add_input {
  border: none !important;
  outline: none !important;
  background: transparent !important;
  width: 100% !important;
  padding: 10px 0 !important;
}

.address {
  width: 100%;
  border: none !important;
  outline: none !important;
  background: transparent !important;
  vertical-align: middle;
}

@media only screen and (max-width: 429px) {
  .address {
    width: 100%;
    border: none !important;
    outline: none !important;
    background: transparent !important;
    vertical-align: middle;
    margin-top: 5px;
  }
}

.addAddressContainer {
  background-color: white;
  box-shadow: 0px -16px 22px 7px rgba(0, 0, 0, 0.1);
  border-radius: 30px 30px 0px 0px;
  /* top: 90% !important; */
  bottom: 0 !important;
  position: absolute;
  width: 100%;
  overflow-y: auto;
}

.address-sheet {
  height: "5vh";
  overflow: "auto"
}

.searchBox {
  position: absolute !important;
  z-index: 1;
  top: 70px;
  padding: 0 10px;
  width: 100%;
}

.search-location {
  border: 0;
  width: 100% !important;
  height: 50px !important;
  padding: 0 30px 0 12px !important;
  border-radius: 0px !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3) !important;
  font-size: 14px !important;
}