/* Position the reCAPTCHA widget off-screen */
.grecaptcha-badge {
  position: fixed;
  bottom: -100px;
  left: -100px;
  visibility: hidden;
}

/* Make the reCAPTCHA widget transparent */
.grecaptcha-badge > div {
  opacity: 0;
}
