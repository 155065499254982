.sharehead {
    background: #002236 !important;
    box-shadow: -2px 2px 4px rgba(204, 203, 203, 0.25) !important;
    border-radius: 0 0 24px 24px !important;
    height: 280px;
}

.business-head {
    background: #002236 !important;
    box-shadow: -2px 2px 4px rgba(204, 203, 203, 0.25) !important;
    border-radius: 0 0 24px 24px !important;
    height: 280px;
}

.m-bottom{
    margin-bottom: 20px;
    background: #002236;
    border-radius: 0 0 30px 30px;
    padding: 20px 20px 20px;
    text-align: center;
}

.imageCont {
    display: inline-block;
}

.imageCont img{
    height: 140px;
    object-fit: cover;
}

.plogooo {
    margin: 0 auto !important;
    padding-top: 15px;
    display: inherit !important;
}

.button-close {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: absolute;
    right: 10px;
    height: 32px;
    width: 32px;
    background-color: white;
    border-radius: 50%;
}

.plogoooo {
    margin: 40px auto !important;
    display: inherit !important;
    height: 140px !important;
    width: 140px !important;
    border-radius: 50% !important;
}

.bsaccount {
    font-size: 18px !important;
    font-weight: 700 !important;
    color: #002236;
    text-align: center !important;
    padding: 10px 0 !important;
}

.dcontain {
    background-color: #F9F9F9;
    border-radius: 16px;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start !important;
    padding: 10px;
    position: relative;
}

.locicon {
    margin-right: 10px;
}

.locationhead {
    font-size: 15px !important;
    color: #002236;
    font-weight: 600 !important;
    /* min-width: 78vw; */
    border: none !important;
    /* background-color: #F9F9F9; */
}

.locationtitle {
    font-size: 13px !important;
    color: #002236;
    font-weight: 400 !important;
    max-width: 80vw !important;
}

.dflexxx {
    margin-top: 10px !important;
}

.copy {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: #E04E2C;
    margin-top: 20px !important;
    position: absolute;
    right: 15px;
}

.dcontainnnnn {
    align-items: unset !important;
}

.callseller {
    color: white !important;
    background-color: #E04E2C !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    border-radius: 8px !important;
    padding: 10px 30px !important;
    text-transform: capitalize !important;
}

.chatseller {
    background-color: #55CD6C !important;
    border-radius: 8px !important;
    padding: 11.5px 40px !important;
    margin-left: 5px !important;
}

.mbottommm {
    margin-bottom: 10px !important;
}

.borderbottomm {
    border-bottom: 4px solid #E3EBEA;
    margin: 15px 0;
}

.youracc {
    color: #002236 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}

.css-10hburv-MuiTypography-root {
    color: #002236 !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    opacity: .8;
}

.css-1p823my-MuiListItem-root {
    padding-left: 0 !important;
}

.css-1mcnwpj-MuiList-root {
    max-width: unset !important;
}

.callsellerr {
    color: white !important;
    background-color: #E04E2C !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    border-radius: 12px !important;
    padding: 15px 0 !important;
    width: 160px !important;
}

.callsellerrr {
    color: white !important;
    background-color: #E04E2C !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    border-radius: 12px !important;
    padding: 10px 0 !important;
    flex: 1 !important;
    margin-right: 10px !important;
    text-transform: capitalize !important;
}

.chat-sellerr {
    background-color: #55CD6C !important;
    border-radius: 12px !important;
    padding: 15px 0 !important;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    margin-right: 5px !important;
    flex: 1;
}

.chatsellerrr {
    background-color: #55CD6C !important;
    border-radius: 12px !important;
    padding: 5px 0 !important;
    width: 95px !important;
    border: none !important;
    /* margin-right: 20px !important; */
}

.dflexxxxx {
    display: flex;
    margin-bottom: 20px;
}

.placedorder {
    text-align: center;
    color: #002236 !important;
    font-size: 18PX !important;
    font-weight: 700 !important;
    margin-top: 30px !important;
}

.placedordercon {
    text-align: center !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #002236 !important;
    margin: 10px 0 !important;
}

.mbuttonnm {
    font-size: 12px !important;
    font-weight: 700 !important;
    color: #F6F6F9 !important;
    background-color: #E3552E !important;
    width: 100%;
    padding: 16px 0 !important;
    border-radius: 16px !important;
    margin-bottom: 15px !important;
}

.mbuttonnmmm {
    margin-bottom: 45px !important;
    background-color: #002236 !important;
}

.bs-eb {
    margin-bottom: 20px !important;
}

.bsdlay {
    display: flex;
}