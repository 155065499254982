@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.centerrimglogo {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.Create {
    font-size: 18px !important;
    font-weight: 700 !important;
    color: #002236;
    text-align: center;
    margin-top: 16px !important;
}

.uimage {
    height: 106px;
    width: 106px;
    border-radius: 50%;
    margin: 20px auto;
    position: relative !important;
}

.business-image {
    height: 106px !important;
    width: 106px !important;
    display: flex;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    overflow: hidden;
}

.business-image::before {
    content: url('../../images/business/cuser-dark.png');
    height: 106px !important;
    border-radius: 100%;
    width: 106px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}

.business-img {
    height: 106px !important;
    width: 106px !important;
    position: relative !important;
    border-radius: 100%;
    background-color: white !important;
}

.business-img__round {
    position: absolute;
    height: 106px;
    width: 106px;
    object-fit: cover;
    border-radius: 100%;
}

.del-icon {
    position: absolute !important;
    left: 50px !important;
    top: 6px !important;
}

.pnuser {
    position: absolute !important;
    height: 100% !important;
    width: 100% !important;
    object-fit: cover !important;
    border-radius: 50%;
}

.imginputss {
    display: flex;
    align-items: center;
    border: 1px solid #C7D6D5;
    padding: 10px;
    border-radius: 12px;
    margin: 10px 0;
}

.button-modal {
    color: #f5f5f5 !important;
    width: 92% !important;
    padding: 16px 0px !important;
    border-radius: 16px !important;
    position: absolute !important;
    left: 4%;
    bottom: 20px !important;
}

.imgrightt {
    margin-right: 10px;
}

.css-v4u5dn-MuiInputBase-root-MuiInput-root:before {
    border-bottom: unset !important;
}

.workingdays {
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #01040D !important;
    margin-bottom: 0 !important;
}

.choosetime {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}

.timee {
    height: 65px;
    width: 160px;
    font-weight: 700;
    font-size: 17px;
    background: #FFFFFF;
    border: 1px solid #C7D6D5;
    border-radius: 16px;
    padding: 20px;
    outline: none;
}

.textareaa {
    width: 100%;
    margin: 20px 0 !important;
}

.css-85zwa9-MuiInputBase-root-MuiFilledInput-root:before {
    border: unset !important;
}

.childdesign {
    border: 1px solid #C7D6D5;
    border-radius: 16px;
    margin-bottom: 10px;
    display: flex;
    padding: 10px;
    align-items: center;
}

.locicon {
    margin-right: 10px;
}

.locationhead {
    font-size: 15px !important;
    color: #002236;
    font-weight: 600 !important;
    /* max-width: 90vw; */
}

.locationtitle {
    font-size: 13px !important;
    color: #002236;
    font-weight: 400 !important;
    max-width: 60vw;
}

.continueebtnnn {
    background-color: #e3552e !important;
    width: 100%;
    border-radius: 16px !important;
    text-transform: capitalize !important;
    padding: 15px 0 !important;
    margin: 0 auto !important;
    margin: 14px 0px 37px 0 !important;
}

.bs-working {
    margin-top: 16px;
    display: grid !important;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: repeat(2,1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}


.css-v4u5dn-MuiInputBase-root-MuiInput-root:after {
    border-bottom: unset !important;
}

.bs-working-dayy:checked {
    background-color: #E3552E !important;
    color: white !important;
}

.work-container {
    position: relative;
    margin-bottom: 0;
}

.work-container input {
    visibility: hidden;
    position: absolute;
}

.mark {
    height: 36px;
    border-radius: 12px;
    background-color: #F4F5F6;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
}

.work-mark {
    background-color: #E3552E;
    color: white;
}

.space-bottom {
    height: 25px;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #C7D6D5 !important;
}

.business-url {
    padding: 0px !important;
    font-size: 18px !important;
    border: 1px solid #ccc !important;
    border-radius: 5px !important;
    outline: none !important;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.input-group-addon {
    font-size: 15px;
    font-weight: 700;
    line-height: 18.23px;
    text-align: center;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
}

.business-input {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
    display: table-cell;
    padding: 1px 1px;
    border: 1px solid #ccc;
    margin: 0;
    border-radius: 16px;
    outline: none;
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}

