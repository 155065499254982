.main {
  margin-top: 0 !important;
  padding-top: 10px !important;
  height: 100% !important;
}

.phorizontalll {
  padding-top: 10px !important;
}

.last-address:last-child {
  margin-bottom: 50px !important;
}


.da-content {
  display: flex !important;
  background-color: #f9f9f9 !important;
  border-radius: 16px !important;
  margin-top: 10px !important;
  align-items: flex-start !important;
  gap: 10px !important;
  padding: 12px 10px !important;
}

.da-contentHeading {
  color: #002236;
  font-weight: 600 !important;
  font-size: 15px !important;
}

.button-tab {
  color: #e3552e !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  text-transform: none !important;
}

.da-contentText {
  color: #002236;
  font-weight: 400 !important;
  font-size: 13px !important;
  overflow-wrap: anywhere;
}

.add-button {
  color: white !important;
  background-color: #e3552e !important;
  bottom: 0 !important;
  width: 100% !important;
  padding: 16px 20px !important;
  margin-bottom: 18px !important;
  border-radius: 16px !important;
}

.inactive_logo {
  display: flex;
  align-items: center !important;
  justify-content: center;
  margin: 0, auto;
  margin: 0 auto;
  margin-top: 150px;
  height: 19vh;
}

.inactive_text {
  text-align: center;
  margin-top: 10px;
  font-size: 22px;
  font-weight: 600;
  color: #002236;
}