.parent {
  display: flex !important;
  overflow: hidden !important;
  height: 100vh !important;
  width: 100vw;
  position: relative;
}

.upperlayerone::-webkit-scrollbar {
  display: none;
}

.upperlayerone {
  background-color: white;
  height: 100vh;
  width: 100vw;
  position: absolute !important;
  transition-duration: 500ms !important;
  overflow: scroll !important;
  z-index: 100;
}

.upperlayertwo {
  z-index: 1;
  transition-duration: 500ms !important;
  height: 80vh;
  width: 100vw;
  margin-top: 10vh !important;
  margin-left: 250px;
  /* margin-left: 65vw; */
  position: absolute !important;
  border-bottom-left-radius: 24px;
  border-top-left-radius: 24px !important;
  overflow: hidden !important;
  background-color: white;
}

.lowerlayer {
  background-color: #e3552e;
  /* height: 100vh !important; */
  width: 100vw;
  padding: 0 10px;
}

.shadow {
  display: none;
}

.aavtarr {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.aavtarracc {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: white;
  width: 41vw;
  text-decoration: underline;
}

/* -------------------------------------------- */
.ulupper {
  background: #002236;
  border-radius: 0 0 30px 30px;
  padding: 0 20px;
  padding-bottom: 24px;
  width: 100vw;
  z-index: 10 !important;
}


.register-header,
.home-header {
  background: #002236;
  position: sticky;
  top: 0;
  z-index: 4;
}

.ham {
  color: white;
}

.hammenuu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.ham-menu {
  padding: 15px 20px;
}

.ppppaddress {
  font-size: 16px !important;
  color: white !important;
  font-weight: 700 !important;
  padding: 15px 0 !important;
}

.pickbox {
  background-color: white !important;
  /* height: 65px !important; */
  border-radius: 16px;
  padding: 15px 24px;
  display: flex;
  align-items: center !important;
  position: relative !important;
}

.pickboxx {
  background-color: #f9f9f9 !important;
}

.pickboxtitle {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #002236 !important;
}

.pickboxcon {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #9d9e9e !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.pickboxloc {
  height: 32px !important;
  width: 32px !important;
  margin-right: 10px !important;
}

.pickboxicon {
  padding-left: 15px;
}

.ullower {
  padding: 24px 20px;
}

.booknowbox {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.date-time {
  height: 60px;
  background-size: 26px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-time1 {
  background-color: #002236;
  background-size: 26px;
  background-position: center;
  background-repeat: no-repeat;
  height: 60px;
  border: 1px solid #002236;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.booknowbuttonn {
  text-align: center !important;
  color: #c7d6d5 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  border: 1px solid#C7D6D5 !important;
  border-radius: 16px !important;
  pointer-events: none;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.booklaterbuttonn {
  margin-left: 15px;
  text-align: center !important;
  color: #c7d6d5 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  border: 1px solid#C7D6D5 !important;
  border-radius: 16px !important;
  pointer-events: none;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.booknowbuttonnsuccess {
  text-align: center !important;
  padding: 20px 0 !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  border-radius: 16px !important;
  width: calc(100% - 80px);
  background: #e3552e !important;
}

.booklaterbuttonnsuccess {
  margin-left: 16px;
  text-align: center !important;
  cursor: pointer;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  border-radius: 16px !important;
  width: calc(100% - 80px);
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.booknowclock {
  /* width: 20px !important; */
  opacity: 0.5;
}

.booknowclock2 {
  opacity: 1;
}

.mbottom {
  border-bottom: 2px solid #e3ebea;
}

.ppickboxtitle {
  margin: 15px 0 !important;
}

.findstore {
  width: 100% !important;
}

.miconbox {
  height: 70px;
  width: 70px;
  background-color: #f2fffc;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.miconboximg {
  position: absolute;
  height: 50%;
  width: 50%;
}

.miconboxmain {
  display: flex;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center;
}

.miconcontent {
  color: #002236 !important;
  font-size: 13px !important;
  font-weight: 700 !important;
}

.aavtarrname {
  font-size: 24px !important;
  color: white;
  font-weight: 700 !important;
}

.aavtarlay {
  display: flex;
  margin-top: 34px;
  margin-bottom: 15px;
}

.aavtarrcountry {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: rgba(255, 255, 255, 0.8);
}

.user-profile {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.user-image {
  float: left
}

.switch-business {
  position: absolute;
  bottom: 4%;
  right: 5%;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: white;
  /* width: 41vw; */
  text-decoration: underline;
}

.aavtarricon {
  margin-right: 10px;
  float: left;
}

.aavtarrorder {
  font-size: 15px !important;
  font-weight: 400 !important;
  color: white;
  float: left;
}

.aavtarlaybox {
  display: flex;
  /* margin-bottom: 35px; */
  display: inline-block;
}

.aavtarlayboxx {
  display: flex;
  margin-bottom: 35px;
  display: inline-block;
  background-color: #e97758;
  padding: 3px 10px;
  border-radius: 16px;
}

.aavtarlayboxlogout {
  margin-top: 61px !important;
}

.aavtarrorderdelete {
  position: absolute;
  bottom: 13vh;
}

.booknowbuttonnn {
  color: #c7d6d5 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  margin: 0 auto !important;
  display: inherit !important;
}

.booknowbuttonnnn {
  padding: 7px 0 !important;
}

.m-lay-content {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 190px;
}

.aavtarlayboxxx {
  position: absolute;
  bottom: 25%;
}

.aavtarrorderrrr {
  position: absolute;
  bottom: 15%;
}

.avatar-delete {
  position: absolute;
  bottom: 44px;
  text-decoration: underline;
}

.avatar-legal {
  position: absolute;
  bottom: 94px;
  text-decoration: underline;
}

/* ----------------------------------- */
@media only screen and (max-width: 766px) {
  .shadow {
    background-color: #e97758;
    width: 40px;
    height: 70vh;
    position: absolute;
    left: 58vw;
    bottom: 0;
    margin-bottom: 15vh;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    display: unset;
  }
}

.welComeNinja {
  color: #002236 !important;
  text-align: center !important;
  font-size: 20px !important;
  font-weight: 700 !important;
}

.aavtar-name {
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 29px !important;
  letter-spacing: 0em !important;
  text-align: left;
  color: white;
}

.aavtar-country {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 19px !important;
  letter-spacing: 0em !important;
  margin: 9px 0 26px 0 !important;
  text-align: left;
  color: white;
}

.inputHolder {
  position: relative;
  flex: 1;
  margin-left: 10px;
  overflow: hidden;
}

.inputHolder input,
.inputHolder textarea {
  font-size: 18px;
  color: #002236;
  border: 0;
  width: 100%;
  font-weight: 700;
  padding: 6px 4px 15px !important;
  background: transparent;
}

.inputHolder input.ps-0 {
  padding-left: 0 !important;
}

.inputHolder input.pe-0 {
  padding-right: 0 !important;
}

.inputHolder select {
  font-size: 18px;
  color: #002236;
  border: 0;
  width: 100%;
  font-weight: 700;
  padding: 6px 0px 15px !important;
  background: transparent;
}

.inputHolder label {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 4px;
  margin: 0;
  transform: translateY(-12px);
  color: #9D9E9E;
  font-weight: 500;
  transition: 200ms all;
}

.inputHolder input:focus+label,
.inputHolder textarea:focus+label,
.inputHolder input:not(:placeholder-shown)+label,
.inputHolder textarea:not(:placeholder-shown)+label,
.inputHolder select:not([value=""])+label {
  font-size: 12px;
  transform: none;
  font-weight: 700;
}

.singleLineText {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  color: #002236 !important;
  padding: 6px 4px 15px !important;
}

.singleLineText:empty {
  display: none;
}