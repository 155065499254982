.finding-ninja {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.heading {
  color: #002236;
  font-size: 18px !important;
  font-weight: 700 !important;
}

.description {
  font-size: 15px !important;
  font-weight: 500 !important;
  padding: 10px !important;
}

.counter {
  margin-top: auto;
  margin-bottom: auto;
}

.bookingnumber {
  color: #1a384a;
  font-weight: 700 !important;
  font-size: 32px !important;
}
.timer {
  color: #1a384a;
  font-weight: 700 !important;
  font-size: 32px !important;
}

.cancelFinding {
  color: #e3552e;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.chooseReason {
}

.chooseReason p {
  font-size: 16px !important;
  padding: 12px 0;
  font-weight: 400;
  border-bottom: 1px solid #e8ecee;
}
.backtohome {
  color: #e3552e;
}
.backtohome:hover {
  cursor: pointer;
}
