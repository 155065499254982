.acmain {
  margin-top: 8vh;
  /* padding:  10px !important; */
}

.couponContainer {
  margin-top: 24px;
}

.stickyBox{
  position: sticky;
  top: 60px;
  background: #fff;
  z-index: 1;
  padding-top: 16px;
}

.acapplybox {
  margin-bottom: 24px;
  padding: 16px;
  background: #E3552E;
  border-radius: 20px;
}

.acapplycode {
  font-size: 18px !important;
  font-weight: 700 !important;
  color: white;
  text-align: center;
  margin-bottom: 16px !important;
}

.separator {
  height: 40px;
  display: flex;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
}

.dots {
  flex: 1;
  border-bottom: 2px dashed #B13B1B;
}

.separator:after,
.separator:before {
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
}

.separator:before {
  left: 0;
}

.separator:after {
  right: 0;
}

.acapplyinput {
  position: relative !important;
  background-color: white;
  border-radius: 16px;
  border: 0;
  height: 65px;
  width: 100%;
  padding: 16px 20px;
  font-size: 16px;
}

.acapplybutton {
  position: relative !important;
  width: 100%;
  background-color: #002236 !important;
  color: white !important;
  height: 60px;
  font-size: 16px !important;
  font-weight: 700 !important;
  border-radius: 16px !important;
  text-transform: capitalize !important;
}

.acavailable {
  color: #002236 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 30px 10px !important;
}

.coupon-header_notification {
  position: fixed !important;
  top: 0 !important;
  width: 100vw !important;
  box-shadow: 0 0 3px 2px #e3e3e3 !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0 16px !important;
  height: 62px !important;
  margin-bottom: 10px !important;
  background-color: white !important;
  z-index: 10 !important;
  outline: none !important;
}

.acdiscountapply {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.acdiscountapplybutton {
  color: #002236 !important;
  font-size: 15px !important;
  font-weight: 700 !important;
  border: 1px solid #e3552e !important;
  border-radius: 10px !important;
  padding: 3px 15px !important;
  text-transform: capitalize !important;
}

.acdiscountapplycouponcodee {
  border-radius: 8px;
  padding: 5px 30px 5px 15px;
  background: #E3552E;
  height: 34px;
  position: relative;
}

.acdiscountapplycouponcodee:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 100%;
  left: 0;
  top: 0;
  border-right: 2px dashed #B13B1B;
}

.acdiscountapplycouponcodee:after {
  content: '';
  position: absolute;
  left: -7px;
  width: 14px;
  height: 14px;
  background: #fff;
  border-radius: 50%;
  top: 50%;
  margin-top: -7px;
}

.acmarginleft {
  margin-left: 15px !important;
  color: white;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.acdiscountdesc {
  font-size: 15px !important;
  color: #002236 !important;
  font-weight: 700 !important;
  padding: 10px 0 !important;
}

.acdiscountdescription {
  font-size: 14px !important;
  color: #002236 !important;
  font-weight: 400 !important;
}

.acmarginy {
  border-bottom: 1px solid #e3ebea !important;
  margin: 0 0 20px;
}



.no-coupon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-coupon {
  font-size: 15px !important;
  color: #002236;
  font-weight: 600 !important;
}