.otp_Input_created {
  height: 45px !important;
  width: 45px !important;
  border: 1px solid #f4f6f8 !important;
  color: #002236 !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  background-color: white !important;
  border-radius: 12px !important;
  text-align: center !important;
  box-shadow: 0px 4px 50px 0px #0000000D;
  font-family: 'Cabin', sans-serif !important;
}
.otp_Input_created:focus {
  border: 1px solid #e3552e !important;
  outline: none !important;
}
.otp_input_box {
  margin: 0 auto !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
