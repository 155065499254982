.main{
    padding:unset !important;
    margin-top: 8vh;
}
.nHeading{
    color:#002236 !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    margin: 16px 0 16px !important;
}
.notificationBox{
    background-color: rgba(224, 78, 44, 0.05) !important;
    width:100vw !important;
    display:flex !important;
    align-items: flex-start !important;
    border-color: red !important;
    padding:15px !important;
    margin-bottom: 5px !important;
}
.n-subheading{
    color: #000000 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}
.n-content{
    color: #000000 !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    opacity: 0.6 !important;
}
.n-contentBox{
    padding:2px;
    width: 80vw;
}
.btnn{
    margin-top: 10px !important;
    margin-right: 10px!  important;
    color:#F6F6F9 !important;
    border-radius: 8px !important;
    padding:5px 15px !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    width:30vw !important;
}
.accept{
    background-color: #E3552E !important;
}
.deny{
    background-color: #002236 !important;
}
.n-time{
    color:#E04E2C;
    font-weight: 500 !important;
    font-size: 12px !important;
}
.notificationBox2{
    display: flex !important;
    align-items: flex-start !important;
    padding:0 15px;

}
.n-read-button{
    color:white !important;
    background-color: #E3552E !important;
    /* position: absolute !important; */
    position:fixed !important;
    bottom: 70 !important;
    width: calc(100% - 20px) !important;
    padding:16px 20px !important;
    border-radius: 16px !important;
    /* margin: 0 auto !important;
    display: inherit !important; */

}
.read-all-n{
    position: fixed !important;
    bottom: 79px;

}
.map_img{
    height: 45px !important;
    width: 45px !important;
    border-radius: 16px !important;
    margin-right: 10px !important;
}